import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import * as style from '../assets/style/pages/page.module.scss';

 const PrivacyPolicyPage = ({ location, data }) => {
  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'Polityka prywatności', url: '/polityka-prywatnosci' }
  ];

  return (
    <Layout>
      <Seo title="Polityka prywatności">
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.section}>
        <div className="container">
          <h1>{data.cookiePolicy.title.value}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.cookiePolicy.text.value }}></div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    cookiePolicy: cockpitCookiePolicyPage {
      title {
        value
      }
      text {
        value
      }
    }
  }
`;

export default PrivacyPolicyPage;
